/* https://www.color-blindness.com/color-name-hue/ */
:root {
  --color-white: #fff;
  --color-black: #000;
  --color-dodger-blue: #0091ff;
  --color-solitude: #f0f4f8;
  --color-medium-turquoise: #39c1cb;
  --color-oyster-bay: #d9e2ec;
  --color-horizon: #627d98;
  --color-echo-blue: #9fb3c8;
  --color-cyprus: #102a43;
  --color-mountain-meadow: #17b897;
  --color-red: #ff0000;

  --rgb-white: 255, 255, 255;
  --rgb-black: 0, 0, 0;
  --rgb-cyprus: 16, 42, 67;
  --rgb-oyster-bay: 217, 226, 236;
  --rgb-dodger-blue: 0, 144, 255;

  --theme-background: var(--color-solitude);
  --theme-text: var(--color-black);
  --theme-text-secondary: var(--color-horizon);
  --theme-area-background: var(--color-white);
  --theme-area-box-shadow: 0 0 16px rgba(16, 42, 67, 0.08);
  --theme-area-box-shadow-inset: inset 0 0 16px rgba(16, 42, 67, 0.08);
  --theme-link-color: var(--color-cyprus);
  --theme-link-color-hover: var(--color-dodger-blue);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--theme-text);
  background-color: var(--theme-background);
  font-size: 15px;
  line-height: 1.0666;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 80px;
}

.container {
  width: 100%;
}

.h1 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  color: var(--color-white);
  margin: 0;
}

.h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: var(--color-white);
  margin: 0;
}

.h3 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color-white);
  margin: 0;
}

.h4 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: var(--color-white);
  margin: 0;
}

.text-black {
  color: var(--theme-text);
}

.tableSectionWrap {
  box-shadow: 0 32px 64px rgba(var(--rgb-cyprus), 0.08);
  overflow: hidden;
}

.grid {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  margin-top: 32px;
}

.grid.double {
  grid-template-columns: repeat(2, 1fr);
}

.card {
  border-radius: 8px;
  background: var(--theme-area-background);
  box-shadow: var(--theme-area-box-shadow);
  padding: 24px;
  margin: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-out;
}

.size1,
.size2,
.size3,
.size4,
.size5,
.size6 {
  grid-column: span 1;
}

.cardTitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--theme-text-secondary);
  margin: 0 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardData {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: var(--theme-text);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 40px;
  margin: 0;
  transition: all 0.3s ease-out, opacity 0.6s ease-out;
  opacity: 1;
}

.cardData ~ .cardData {
  min-height: 32px;
}

.cardData span:first-of-type {
  margin-right: 16px;
}

.cardData.compact {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  min-height: 0;
}



.noMargin {
  margin: 0;
}

.card.alt {
  background: var(--theme-background);
  box-shadow: var(--theme-area-box-shadow-inset);
}

.card.compact {
  padding: 12px 24px;
}

.card.compact .cardTitle {
  margin: 0 0 8px;
}

@media (min-width: 480px) {
  .container {
    padding: 0 16px;
  }
}

@media (min-width: 768px) {
  .h2 {
    font-size: 32px;
    line-height: 48px;
  }

  .h3 {
    font-size: 20px;
    line-height: 24px;
  }

  .h4 {
    font-size: 14px;
    line-height: 24px;
  }

  .tableSectionWrap {
    border-radius: 8px;
  }

  .grid,
  .grid.double {
    gap: 24px;
    grid-template-columns: repeat(6, 1fr);
  }

  .size1 {
    grid-column: span 1;
  }

  .size2 {
    grid-column: span 2;
  }

  .size3 {
    grid-column: span 3;
  }

  .size4 {
    grid-column: span 4;
  }

  .size5 {
    grid-column: span 5;
  }

  .size6 {
    grid-column: span 6;
  }

  .sectionTitle {
    grid-column: span 6;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1680px;
    margin: 0 auto;
    padding: 0 32px;
  }
}

.rd3t-link {
  fill: none;
  stroke: var(--color-oyster-bay) !important;
}

.w-full {
  width: 100%;
}

.chartTooltip {
  background: var(--theme-area-background);
  box-shadow: var(--theme-area-box-shadow);
  padding: 24px;
  border-radius: 8px;
}

.chartTooltipTitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--theme-text-secondary);
}

.chartTooltipData {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: var(--theme-text);
}

.chartHeader {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
}

.chartHeaderOptions {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

.chartHeaderOptionsVertical {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  justify-content: flex-end;
}

.relative {
  position: relative;
}

.flex-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

.chartWrap {
  overflow: hidden;
  width: 100%;
}

.chartWrap.square {
  aspect-ratio: 1;
}

.chartWrap.aspect3_2 {
  aspect-ratio: 3/2;
}

.chartWrap.wide {
  aspect-ratio: 2;
}

.chartTooltip {
  background: var(--theme-area-background);
  box-shadow: var(--theme-area-box-shadow);
  padding: 24px;
  border-radius: 8px;
}

.chartTooltipTitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--theme-text-secondary);
}

.chartTooltipData {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: var(--theme-text);
}
