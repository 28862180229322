.wrap {
  position: relative;
}

.buttonBorder {
  background: var(--color-oyster-bay);
  border-radius: 9px;
  padding: 1px;
}

.buttonBorder.active {
  background: linear-gradient(90deg, var(--color-dodger-blue) 0%, var(--color-medium-turquoise) 100%);
}

.targetBtn {
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background: var(--color-white);
  border: none;
  border-radius: 8px;
  color: var(--color-horizon);
  box-shadow: 0 2px 4px var(--color-solitude);
  outline: none;
  margin: 0;
  width: 100%;
}

.defaultSortName {
  color: var(--color-black);
  font-weight: 600;
  margin-left: 8px;
}

.buttonBorder.active .targetBtn,
.targetBtn:focus,
.targetBtn:hover {
  color: var(--color-cyprus);
}

.buttonBorder.active .dropdownIcon,
.targetBtn:focus .dropdownIcon,
.targetBtn:hover .dropdownIcon {
  color: var(--color-horizon);
}

.dropdownIcon {
  margin-left: auto;
  color: var(--color-echo-blue);
}

.dropdownWrap {
  background: var(--color-white);
  border: 1px solid var(--color-oyster-bay);
  box-shadow: 0 32px 64px rgba(var(--rgb-black), 0.16);
  border-radius: 8px;
  min-width: 80px;
  padding: 2px;
  display: none;
  width: 100%;
  z-index: 2;
}

.dropdownWrap.active {
  display: block;
}

.dropdownItem {
  border-radius: 8px;
  padding: 8px;
  display: flex;
  width: 100%;
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--color-horizon);
}

.dropdownItem + .dropdownItem {
  margin-top: 2px;
}

.dropdownItem.active {
  color: var(--color-cyprus);
  background: var(--color-solitude);
}

.dropdownItem:hover {
  background: var(--color-solitude);
}
