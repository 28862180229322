.wrap {
  position: relative;
}

.input[type=search]::-ms-clear,
.input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.input[type="search"]::-webkit-search-decoration,
.input[type="search"]::-webkit-search-cancel-button,
.input[type="search"]::-webkit-search-results-button,
.input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.input {
  border: 1px solid var(--color-oyster-bay);
  box-shadow: 0 2px 4px var(--color-solitude);
  color: var(--color-black);
  appearance: none;
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 56px 12px 16px;
  outline: none;
  border-radius: 8px;
}

.input:focus {
  border-color: var(--color-dodger-blue);
}

.input::placeholder {
  color: rgba(var(--rgb-black), 0.64);
}

.button {
  width: 32px;
  height: 32px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  color: rgba(var(--rgb-black), 0.64);
  outline: none;
}

.button:hover,
.button:focus {
  color: var(--color-black);
}

.icon {
  pointer-events: none;
}

@media (min-width: 768px) {
  .wrap {
    width: 380px;
  }
}
@media (min-width: 768px) {
  .wider-wrap {
    width: 555px;
  }
}
