.wrap {
  margin-bottom: 32px;
}

.hgroup {
  margin: 0;
  min-height: 72px;
}

@media (min-width: 768px) {
  .wrap {
    display: flex;
    justify-content: space-between;
  }
}
