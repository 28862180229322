.tabs {
  min-width: 100%;
  padding-top: 4px;
}

.tabsHeader {
  width: min-content;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--theme-background);
  border-radius: 8px;
  padding: 4px;
  margin: 4px 0 4px 4px;
  gap: 4px;
  box-shadow: var(--theme-area-box-shadow-inset);
}

.tabsHeader > .tabToggle {
  padding: 4px 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  border-radius: 4px;
  min-width: 55px;
  text-align: center;
}

.tabsHeader > .tabToggle.active {
  background: white;
  box-shadow: var(--theme-area-box-shadow);
}

.tabs > .tabsContent {
  overflow: hidden;
}

.tabs > .tabsContent > .tabsElement {
  width: 100%;
}
