:root {
  --hover-color: rgba(var(--rgb-oyster-bay), 0.24);
}

.sortWrap {
  border-top: 1px solid var(--color-oyster-bay);
  background: var(--theme-area-background);
  padding: 16px;
}

.tableWrap {
  position: relative;
}

.tableWrap.loading {
  min-height: 100px;
}

.tableWrap.noWrap {
  overflow: hidden;
}

.spinnerWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: rgba(var(--rgb-white), 0.9);
  display: flex;
  justify-content: center;
  padding: 40px 0;
  z-index: 1;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background: var(--theme-area-background);
  border-top: 1px solid var(--color-oyster-bay);
  border-bottom: 1px solid var(--color-oyster-bay);
  display: block;
}

.table thead {
  display: none;
}

.table tbody {
  display: block;
}

.table tr {
  display: block;
  border-top: 1px solid var(--color-oyster-bay);
  border-bottom: 1px solid var(--color-oyster-bay);
  padding: 6px 0;
}

.table tr + tr {
  border-top: none;
}

.table tr:first-of-type {
  border-top: none;
}

.table tr:last-of-type {
  border-bottom: none;
}

.table th {
  padding: 12px 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--theme-text-secondary);
  text-align: left;
  border-top: 1px solid var(--color-oyster-bay);
  border-bottom: 1px solid var(--color-oyster-bay);
}

.table td {
  --label: '';
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  color: var(--color-cyprus);
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: pre;
}

.table td[colspan] {
  justify-content: revert;
}

.table td::before {
  content: var(--label);
  color: var(--theme-text-secondary);
  margin-right: 8px;
  text-align: left;
}

.link {
  text-decoration: underline;
  color: var(--theme-link-color);
}

.highlight {
  background-color: yellow;
}

.link:hover {
  color: var(--theme-link-color-hover);
}

.right.right {
  text-align: right;
}

.center {
  text-align: center;
}

.tooltipRow {
  font-size: 13px;
  line-height: 15px;
  display: flex;
}

.tooltipRow span + span {
  margin-left: 8px;
}

.table.noControls,
.table.noControls tbody tr:last-of-type td {
  border-bottom: 0;
}


@media (min-width: 768px) {
  .sortWrap {
    display: none;
  }

  .tableWrap {
    overflow-y: auto;
  }

  .spinnerWrap {
    align-items: center;
    padding: 0;
  }

  .table {
    display: table;
  }

  .table thead {
    display: table-header-group;
  }

  .table tbody {
    display: table-row-group;
  }

  .table tr {
    display: table-row;
    border: 0;
  }

  .table th {
    padding: 12px 16px;
  }

  .table td {
    display: table-cell;
    border-top: 1px solid var(--color-oyster-bay);
    border-bottom: 1px solid var(--color-oyster-bay);
    padding: 16px;
    text-align: left;
  }

  .table td::before {
    display: none;
  }

  .table tbody tr:hover td,
  .table tbody tr.hovered td {
    background: var(--hover-color);
  }
}

.table.noWrap td,
.table.noWrap th,
.table.noWrap tr {
  white-space: nowrap;
}
